a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}


.section-title {
  text-align: center;
  font-family: 'El Messiri', sans-serif;
}


.scroll-to-fixed-fixed {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: rgb(26, 27, 40);
}

.head-cover {
  margin-top: 0;
  padding: 10px;
  padding-left: 100px;
  padding-right: 100px;
  background-color: #f3f4f8;
}

@media only screen and (max-width: 950px) {
  .head-cover {
    padding-left: 0;
    padding-right: 0;
  }
}


.clr:hover {
  background-color: #2bba00 !important;
  color: #ffffff !important;
}
