@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.main{
    /* height: 100vh; */
    width: 100%;
    overflow: hidden;
    background: rgb(28, 39, 43);
    position: relative;
}
.main .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .7rem 1rem .7rem 2rem;
    /* border-bottom: 1px solid gray; */
    background-color: #222831;
}
.main .header .left h2{
    color: white;
}
.main .header h4{
    text-transform: uppercase;
    text-align: left;
    color: #00bea6;
    /* margin-right: -12rem; */
}
.main .header .right a{
    line-height: 1.5;
    border-radius: 3px;
    border: navajowhite;
    font-weight: 700;
    font-size: 14px;
    padding: 12px 16px;
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: #00bea6 !important;
    border-color: #00bea6 !important;
    margin-left: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
}
.main .header .right button{
    line-height: 1.5;
    border-radius: 3px;
    border: navajowhite;
    font-weight: 600;
    font-size: 14px;
    padding: 12px 16px;
    text-align: center;
    color: rgb(0, 0, 0);
    background-color: #00bea6 !important;
    border-color: #00bea6 !important;
    margin-left: 1rem;
    text-transform: uppercase;
    cursor: pointer;
}
.main .header .right .info{
  margin: 0;
  border-radius: 10px;
  padding: 2px 10px;
  text-transform: lowercase;
}
.main .header .right a:hover,.main .header .right button:hover{
    
    background-color: #028f7c !important;
    border-color: #028f7c !important;
}
.main .header .right a .MuiSvgIcon-root
{
    margin-bottom: -5px;
}
.maincontainer{
    display: flex;
    /* height: 90vh; */
}
.maincontainer .leftmost{
    flex: 0.20;
    padding: 1rem;
    display: block;
    align-items: center;
    flex-direction: column;
    color: white;
    border-right: 1px gray solid;
    text-align: center;
    overflow-y: scroll;
}
.maincontainer .leftmost ul li{
    color: #00bea6;
}
.maincontainer .mid{
    flex: 0.60;
    display: flex;
    justify-content: center;
    margin: 10px;
    /* align-items: center; */
}
.maincontainer .right{
    flex: 0.20;
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 1rem;
    border-left: 1px solid gray;
    overflow-x: scroll;
}
.generate{
    line-height: 1.5;
    border-radius: 3px;
    border: navajowhite;
    font-weight: 700;
    font-size: 14px;
    padding: 12px 16px;
    text-align: center;
    color: rgb(0, 0, 0);
    background-color: #00bea6 !important;
    border-color: #00bea6 !important;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 1rem;
}

.input-box .details{
    font-weight: 500;
    margin-bottom: 5px;
    color: white;
}
.input-box input{
    height: 45px;
    width: 100%;
    outline: none;
    border-radius: 5px;
    margin: 6px 0 10px 0;
    border: 1px solid rgb(107, 107, 107);
    padding-left: 15px;
    font-size: 16px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
    font-weight: 500;
}
.input-box select{
  height: 45px;
  width: 100%;
  outline: none;
  border-radius: 5px;
  margin: 6px 0 10px 0;
  border: 1px solid rgb(107, 107, 107);
  padding-left: 15px;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
  font-weight: 500;
}
.input-box textarea{
    height: 100px;
    width: 100%;
    outline: none;
    border-radius: 5px;
    margin: 6px 0 10px 0;
    border: 1px solid rgb(107, 107, 107);
    padding-left: 15px;
    padding-top: 5px;
    font-size: 16px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
    font-weight: 500;
    font-family: inherit;
}
.right .form{
    z-index: 999999 !important;
    display: flex;
    flex-direction: column;
}
::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }


  @media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
.popup{
  position: fixed;
  /* top: 50%; */
  /* left: 50%; */
  z-index: 100;
  background: rgba;
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 1s ease;
}
.popup-box{
  background: white;
  padding: 2rem;
  border-radius: 5px;
  position: relative;
}
.popup-box button{
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 6px 10px;
  border: none;
  background: #00bea6;
  color: white;
  border-radius: 50%;
  font-weight: bold;
  cursor: pointer;
}
.popup-box .count{
  text-align: center;
  margin-top: 10px;
  background-color: #00bea6;
  padding: 10px;
  border-radius: 10px;
}
.popup-box li{
  color: #00bea6;
  font-weight: 400;
  font-size: 14px;
}
.leftmost .templates{
  padding: 2px;
    overflow: hidden;
    border: 1px solid #00bea6;
    margin: 1rem;
    border-radius: 0px;
    background: #ff57222b;
    cursor: pointer;
    transition: 0.5s;
}
.leftmost .active{
  box-shadow: 0 0 5px 2px #00bea6;
  transform: scale(1.05)
}
.leftmost .templates img{
  width: 100%;
  height: 100%;
}
#toggler {
  margin-left: 1rem;
  padding: 5px;
  background: #00bea6;
  border: none;
  color: white;
  line-height: 1.5;
  border-radius: 3px;
  border: navajowhite;
  font-weight: 700;
  font-size: 11px;
  /* padding: 12px 16px; */
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: #00bea6 !important;
  border-color: #00bea6 !important;
  margin-left: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}
.header .left {
  display: flex;
  align-items: center;
}

button:disabled,
button[disabled]{
  background-color: #afafaf !important;
  color: #666666;
  cursor: not-allowed;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-bottom: 10px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked + .slider {
  background-color: #2196F3;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}